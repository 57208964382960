import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Select, notification, Tooltip, Icon, Upload, Progress, Modal } from "antd";
import axios from "axios";
import { Upload as UploadVimeo } from "tus-js-client"
import { GlobalContext } from "../../../GlobalState"

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

class FormUploadFicheiro extends Component {
    static contextType = GlobalContext
    state = {
        fileList: [],
        ficheiros: [],
        //VALIDACOES
        validateStatus: "",
        help: "",
        totalBytes: 0,
        formatoValido: true,
        //LOADING
        loading: false,
        iconLoading: false,
    };

    importar = videoId => {
        this.setState({ iconLoading: true })

        var item = new FormData()
        item.append("videoConfId", this.props.videoConfId)
        this.state.fileList.forEach(file => {
            item.append("anexo[]", file);
        });

        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/importar-ficheiro",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Ficheiro importado!")

                this.setState({ iconLoading: false })
                this.props.form.resetFields()
                this.props.atualizarListagemVisivel();
                this.props.onClose();
            })
            .catch(error => {
                this.setState({ iconLoading: false })
                openNotificationWithIcon("error", "Erro", error.response.data)
            })
    }

    carregarFicheiros = () => {
        this.setState({
            loading: true
        })
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/carregar-ficheiros",
            params: {
                videoConfId: this.props.videoConfId
            }
        })
            .then(response => {
                this.setState({
                    ficheiros: response.data.length ? response.data : [],
                    loading: false
                })
            })
            .catch(() => {
                this.setState({ loading: false })
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os videos!")
            })
    }

    excluirFicheiro = (index) => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-videoconferencia/excluir-ficheiro",
                    params: {
                        videoConfId: this.props.videoConfId,
                        index
                    }
                })
                    .then(response => {
                        var ficheiros = this.state.ficheiros;
                        ficheiros.splice(index, 1);

                        this.setState({
                            ficheiros
                        })
                        this.props.atualizarListagemVisivel();
                    })
                    .catch(() => {
                        this.setState({ iconLoading: false })
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!")
                    })
            }
        });
    }

    downloadFicheiro = (index) => {
        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/download-ficheiro",
            params: {
                videoConfId: this.props.videoConfId,
                index
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.caminho;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"]) || value.file.size > 26214400)
                callback("Campo obrigatório")

        callback()
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            this.setState({
                fileList: [],
                ficheiros:[],
                //VALIDACOES
                validateStatus: "",
                help: "",
                totalBytes: 0,
                formatoValido: true,
                //LOADING
                loading: false,
                iconLoading: false,
            }, () => this.carregarFicheiros())
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;

        const {
            fileList,
            ficheiros,
            //VALIDACOES
            validateStatus,
            help,
            //LOADING
            loading,
            iconLoading,
        } = this.state;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias drawer-stepmeets"
                    title="Anexe ficheiros a esta Stepmeet"
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <div className="bloco">
                            <Form className="form-categorias" layout="horizontal">
                                <Form.Item validateStatus={validateStatus} help={help}>
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                validator: this.validar
                                            }
                                        ],
                                        initialValue: this.state.fileList
                                    })(
                                        <Dragger {...props} fileList={this.state.fileList}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexos{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador">Anexos</h3>
                            {loading ?
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                                :
                                <div className="lista-videos">
                                    {!ficheiros.length ? <span>Sem registos</span> : null}
                                    {ficheiros.map((ficheiro, index) => (
                                        <div key={index} className="item-video">
                                            <span className="video-titulo">{ficheiro.split(".").pop() === "zip" ? "Documentos.zip" : ficheiro}</span>
                                            <div className="video-controlos">
                                                <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadFicheiro(index)}>
                                                    <Icon type="download" />
                                                </Link>
                                                <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => this.excluirFicheiro(index)}>
                                                    <Icon type="delete" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                    </button>
                        <button className="botao-principal" disabled={iconLoading} onClick={this.importar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                    </div>
                </Drawer>
            </>
        );
    }
}
const FormFormUploadFicheiro = Form.create({ name: "form-upload-ficheiro" })(FormUploadFicheiro);

export default FormFormUploadFicheiro;
